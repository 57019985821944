import {CompData, ILayout, ILogger, IPageJson, IStructure, IThemeData, StylableCssSiteData} from './types';
import {THEME_DATA} from './consts';
import {createRobustKey, getRefIdFromRobustKey, getStyleIdFromRobustKey} from './utils/internal';

const masterPageChildren = (page: IStructure) => page.children;
const mobilePageChildren = (page: IStructure) => page.mobileComponents;
const componentChildren = (component: IStructure) => component.components;

const flat = (arr: any[]) => [].concat(...arr);

const children = (component: IStructure, isMobile = false): CompData[] => {
    let childrenCollected: CompData[] = [];
    if (isMobile) {
        const comps = mobilePageChildren(component);
        if (comps) {
            childrenCollected = comps;
        }
    } else {
        childrenCollected = flat([masterPageChildren(component), componentChildren(component)]);
    }
    return childrenCollected;
};

function recursiveCallFunc(compsArray: CompData[] | undefined, func: (item: CompData) => void) {
    if (compsArray) {
        compsArray.forEach(comp => {
            if (comp) {
                func(comp);
                recursiveCallFunc(children(comp), func);
            }
        });
    }
}

function runForCompsRecursively(pageJson: IPageJson, isMobileView: boolean, func: (item: CompData) => void) {
    let compsArray: CompData[] | undefined = [];
    if (pageJson.structure) {
        if (isMobileView) {
            compsArray = children(pageJson.structure, isMobileView);
        } else {
            compsArray = children(pageJson.structure);
        }

        recursiveCallFunc(compsArray, func);
    }
    return compsArray;
}

function getCompLayoutMapFromPage(pageJson: IPageJson, isMobileView: boolean) {
    const compLayoutMap: Record<string, ILayout> = {};
    const callbackfn = (comp: CompData) => {
        if (comp.layout) {
            compLayoutMap[comp.styleId] = comp.layout;
        }
    };
    runForCompsRecursively(pageJson, isMobileView, callbackfn);
    return compLayoutMap;
}

function removeHash(id: string) {
    return id.replace('#', '');
}

/**
 * Inside helper function which runs a callback for a map of theme data items
 * @param themeDataItems - map of theme data items
 * @param cb - callback to run for all of them.
 */
function forStyleItems(themeDataItems: Record<string, IThemeData>, cb: (themeDataItem: IThemeData, id: string) => void) {
    Object.keys(themeDataItems).forEach(id => {
        if (id === THEME_DATA) {
            return;
        }
        const themeDataItem = themeDataItems[id] as IThemeData;
        cb(themeDataItem, id)
    });
}

/**
 * Creates and returns a map of refs between items, gathered from themeDataItems and masterThemeDataItems
 * @param themeDataItems - theme data from page
 * @param masterThemeDataItems - theme data from master page
 * @param logger - site-assets logger object to throw warnings if needed
 */
export function createRefArrayMap(themeDataItems: Record<string, IThemeData>, masterThemeDataItems: Record<string, IThemeData> = {}, logger: ILogger) {
    const refArrayMap: Record<string, string> = {}
    // Map refArrays 1st pass - RefArrays
    forStyleItems(themeDataItems, (themeDataItem, id) => {
        if (themeDataItem.type === 'RefArray') {
            themeDataItem.values?.forEach((hashedStyleId => {
                const styleId = removeHash(hashedStyleId)
                let dataItem = themeDataItems[styleId];
                if (!dataItem) {
                    dataItem = masterThemeDataItems[styleId]
                }
                if (!dataItem) {
                    logger.warn(`Missing dataItem for styleId=${styleId}, SHOULD NOT HAPPEN`)
                } else if (!dataItem.type) {
                    logger.warn(`Missing type for styleId=${styleId}, SHOULD NOT HAPPEN`)
                } else{
                    if (dataItem.type === 'VariantRelation') {
                        if (!dataItem.to){
                            logger.warn('VariantRelation missing "to" field, SHOULD NOT HAPPEN')
                        } else {
                            refArrayMap[createRobustKey(removeHash(dataItem.to), id)] = id
                        }
                    } else if (dataItem.type === 'ComponentStyle' || dataItem.type === 'TopLevelStyle'){
                        if (!dataItem.id) {
                            logger.warn(`${styleId} - ${dataItem.type} missing "id" field, SHOULD NOT HAPPEN`)
                        } else {
                            refArrayMap[createRobustKey(dataItem.id, id)] = id
                        }
                    } else {
                        logger.warn(`Unsupported styleItem of type=${dataItem.type} for styleId=${dataItem.id} in stylable-santa-flatten`)
                    }
                }
            }));
        }
    })
    return refArrayMap;
}

/**
 * Gathers stylable, layout and ref data from pages and updates stylableCssSiteData with it.
 * @param logger - site-assets logger object to throw warnings if needed
 * @param pageJson - page json to scan
 * @param stylableCssSiteData - StylableCssSiteData object to update with data on pages
 * @param isMobileView - iff true, uses *mobile* item layout data
 * @param masterPage - master json to scan (not required, but usually needed if pageJson is not masterPage)
 */
export function extractCssDataFromPage(
    logger: ILogger,
    pageJson: IPageJson,
    stylableCssSiteData: StylableCssSiteData,
    isMobileView: boolean,
    masterPage?: IPageJson
) {
    const themeDataItems = pageJson?.data?.theme_data;
    const masterThemeDataItems = masterPage?.data?.theme_data;
    if (!themeDataItems) {
        return;
    }

    // Get layout of every component in page:
    const compLayoutMap = getCompLayoutMapFromPage(pageJson, isMobileView);

    // Create a map for ref items (origin -> refArrayId)
    const refArrayMap = createRefArrayMap(themeDataItems, masterThemeDataItems, logger);

    // 1st phase:
    // Run on all style items in page and store all the ones with stylable:
    forStyleItems(themeDataItems, (themeDataItem, id) => {
        if (
            themeDataItem.style &&
            themeDataItem.style.properties &&
            themeDataItem.style.properties['$st-css'] !== undefined
        ) {
            // Filter out style items which are used by ref, these are scanned in 2nd phase
            if (!Object.keys(refArrayMap).find(key => getStyleIdFromRobustKey(key) === id)) {
                // Store style item:
                stylableCssSiteData.set(id, {
                    content: themeDataItem.style.properties['$st-css'],
                    styleId: id,
                    compLayout: compLayoutMap[id]
                });
            }
        }
    });

    // 2nd phase:
    // Run for all refs in collected refArrayMap:
    Object.keys(refArrayMap).forEach(key => {
        // Get styleId and refId from robust key:
        const styleIdTarget = getStyleIdFromRobustKey(key);
        const refIdSource = getRefIdFromRobustKey(key);

        // Try to get styleItem from page:
        let styleItem: IThemeData = themeDataItems[styleIdTarget];
        // If styleItem not on page try masterPage:
        if (!styleItem && masterThemeDataItems) {
            styleItem = masterThemeDataItems[styleIdTarget];
        }

        // If its a stylable style item, add to data
        if (styleItem &&
            styleItem.style &&
            styleItem.style.properties &&
            styleItem.style.properties['$st-css'] !== undefined) {
            stylableCssSiteData.set(key, {
                content: styleItem.style.properties['$st-css'],
                styleId: styleIdTarget,
                refArrayId: refIdSource,
                compLayout: compLayoutMap[refIdSource]
            });
        }
    })
}
