import semverValid from 'semver/functions/valid';
import { ComponentsMetadata } from '@stylable/webpack-extensions';

export type LoadModule = (req: string) => Promise<any>;
const DEFAULT_MODULE_REPO_URL = 'https://static.parastorage.com/unpkg'

export async function fetchMetadataAndLibCSS(
    libVersion: string,
    loadModule: LoadModule,
    moduleRepoUrl?: string
) {
    if (!semverValid(libVersion)) {
        throw new Error(`invalid semver version ${libVersion}`);
    }
    const baseUrl = `${moduleRepoUrl || DEFAULT_MODULE_REPO_URL}/wix-ui-santa@${libVersion}/dist/statics/`;

    const [metadata, libCSS]: [ComponentsMetadata | undefined, string | undefined] = await Promise.all([
        loadModule(baseUrl + 'wix-ui-santa.metadata.json.bundle.js'),
        loadModule(baseUrl + 'stylable-preview.bundle.js')
    ]);

    if (typeof metadata !== 'object' || typeof libCSS !== 'string') {
        throw new Error(`error fetch required metadata.\nmetadata=${metadata && JSON.stringify(metadata)}\nlibCss=${libCSS}`);
    }

    return { metadata, libCSS };
}
