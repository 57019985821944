import { wixMediaUrlName, wixFontThemesName } from '../consts';
import wixMediaUrl, { wixMediaUrlTransformationPlugin } from './wix-media-url';
import wixFontThemes from './wix-font-themes';

import { TransformationPlugins, InlineModules } from '../types';

export const inlineModules: InlineModules = Object.create(null); // we use create(null) so method that need to be exported to be used within stylable are not exposed (otherwise you could import toString for instance
inlineModules[wixMediaUrlName] = wixMediaUrl;
inlineModules[wixFontThemesName] = wixFontThemes;

export const transformationPlugins: TransformationPlugins = [wixMediaUrlTransformationPlugin];

export function wrapRequireWithInlineModules(requireModule: (id: string) => any, modulesToInline: InlineModules) {
    return (id: string) => {
        const inlineModule: any = modulesToInline[id];
        return inlineModule ? inlineModule : requireModule(id);
    };
}
