const REF_SEPARATOR = '__';

/**
 * Creates a key which consists of styleId and refId (if supplied).
 * The motivation for this is that when two components refer to the same styleId, we have a different result and
 * therefore need a different key.
 * @param styleId - styleId for robust key
 * @param refId - refId for robust key (not required)
 */
export function createRobustKey(styleId: string, refId?: string) {
    if (refId) {
        return `${styleId}${REF_SEPARATOR}${refId}`;
    }
    return styleId;
}

/**
 * extracts the styleId from a robustKey
 * @param robustKey to get styleId from
 */
export function getStyleIdFromRobustKey(robustKey: string) {
    return robustKey.split(REF_SEPARATOR)[0];
}

/**
 * extracts the refId from a robustKey
 * @param robustKey to get refId from
 */
export function getRefIdFromRobustKey(robustKey: string) {
    return robustKey.split(REF_SEPARATOR)[1];
}
