export const METERING_TOTAL = 'stylableTotal';
export const METERING_TRANSFORM = 'transformStylable';
export const METERING_FETCH = 'fetchingPages';
export const METERING_PRE_PARSE = 'preParse';
export const METERING_COLLECT_FONTS = 'collectFonts';

export const METERING_HIST_RESPONSE_SIZE = 'responseSize';
export const METERING_HIST_RESPONSE_SIZE_MASTER_SUFFIX = '_masterPage';

export const CSS_HEADER_UPLOADED_FONTS = '\n/*uploaded fonts*/\n';
export const CSS_HEADER_SITE_CSS = '\n/*site css*/\n';

export const THEME_DATA = 'THEME_DATA';
export const THEME_COLOR_KEY = 'color';
export const THEME_FONT_KEY = 'font';

export const wixMediaUrlName = 'wix-media-url';
export const wixMediaUrlFormatter = 'wixMediaUrl';
export const wixMediaUrlFormatterOriginalArgsParamname = 'formatterValues';

export const wixFontThemesName = 'wix-font-themes';

export const SITE_ST_CSS_PATH = '/site.st.css';
export const mobileMediaQueryVarName = 'mobileMediaQuery';
export const mobileMediaQueryOff = 'screen and (min-width: 1px) and (max-width: 0px)';
export const mobileMediaQueryOn = 'screen and (min-width: 0px)';
