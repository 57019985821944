import coreFontUtils from 'santa-core-utils/dist/fonts';
import {ParsedValue, safeParse} from '@stylable/core';
import postcss from 'postcss';
const { parseValues } = require('css-selector-tokenizer');
const { isUploadedFontFamily, getShortUploadedFontFamily } = coreFontUtils;

type DeclFilter = (decl: postcss.Declaration) => boolean;
type OnFontCallback = (font: string) => void;
type ExtendedParsedValue = ParsedValue & { name?: string };

/*******************************************************************/
/* This logic should be added to Stylable, and imported from there */
/*******************************************************************/
export function findFontsInAst(root: postcss.Root, filter: DeclFilter = () => true, onlyUploaded = false): string[] {
    const fontsSet = new Set<string>();
    root.walkDecls(decl => {
        if (!filter(decl)) { return; }
        processDeclarationFonts(decl, font => {
            fontsSet.add(font);
        });
    });
    let fontsArray = Array.from(fontsSet);
    if (onlyUploaded) {
        fontsArray = fontsArray.filter(fontName => isUploadedFontFamily(fontName))
            .map(font => `${font},${getShortUploadedFontFamily(font)}`)
    }
    return fontsArray;
}

export function findFontsInCss(css: string, declFilter = () => true, onlyUploaded?: boolean) {
    return findFontsInAst(safeParse(css), declFilter, onlyUploaded);
}

export function processDeclarationFonts(decl: postcss.Declaration, onFont: OnFontCallback) {
    // Run only for font props:
    if (decl.prop !== 'font' && decl.prop !== 'font-family') {
        return;
    }
    const ast = parseValues(decl.value);
    ast.nodes.forEach((node: ExtendedParsedValue, index: number) => {
        const nodes = node.nodes;
        const lastChild = nodes[nodes.length - 1];

        if (lastChild) {
            // font is always last. commas split to different nodes, so this assumption holds for multiple fonts as well
            const { type } = lastChild;
            switch (type) {
                case 'item':
                    if (decl.prop === 'font' && index === 0) {
                        if (lastChild.name) {
                            onFont(lastChild.name);
                        }
                    } else {
                        const font = nodes.reduce((fontAgg: string, part: any) => {
                            return `${fontAgg}${part.name}${part.after ? part.after : ''}`;
                        }, '');
                        onFont(font);
                    }
                    break;
                case 'string':
                    if (lastChild.value && !(lastChild.value === `""` || lastChild.value === `''`)) {
                        onFont(lastChild.value);
                    }
                    break;
            }
        }
    });
}
