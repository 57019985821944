import {ILogger, IMetricsReporter} from './types';

export const voidMetricsReporter: IMetricsReporter = {
    runAsyncAndReport: async asyncMethod => await asyncMethod(),
    runAndReport: <T>(method: () => T) => method(),
    reportEndpoint: () => undefined,
    reportError: () => undefined,
    meter: () => undefined,
    histogram: () => undefined,
    reportAsyncWithCustomKey: async (asyncMethod, _methodName, _key) => await asyncMethod()
};

export const voidLogger: ILogger = {
    debug: () => undefined,
    warn: () => undefined,
    error: () => undefined,
    trace: () => undefined,
    info: () => undefined
}
