import { callStylableBuild, Pages } from './stylable-santa-server-funcs';
import {IMetricsReporter, ISiteAssetsCallParams, ITopology, IModuleFetcher, ILogger} from './types';
import {voidLogger, voidMetricsReporter} from './mock-injected-apis';

type ParamsAndPages = ISiteAssetsCallParams & { pages: Pages };
interface IAPIContext {
    metricsReporter?: IMetricsReporter;
    topology: ITopology;
    moduleFetcher: IModuleFetcher;
    logger: ILogger;
}

const api = ({ metricsReporter, topology, moduleFetcher, logger }: IAPIContext) => ({
    execute: ({ pages /*, clientSpecMap*/, ...params }: ParamsAndPages) =>
        callStylableBuild(pages, params, metricsReporter || voidMetricsReporter, moduleFetcher, topology, logger || voidLogger),
});

export default api;

export * from './index';
