// Singleton context for inline modules

import { StylableMeta } from '@stylable/core';

import { ILayout, DeclarationMap, ThemeDataProps } from '../types';
import { getFontThemeClasses } from '../fonts/font-utils';

export interface ILayoutMapItem {
    layout: ILayout;
    originalWidth: number;
    originalHeight: number;
}

/**
 * runs through ast and finds all occurrences of wixMediaUrl formatter.
 * For every occurrence, an entry with the matching component layout is saved onto uriToLayoutMapping
 * @param meta - stylable meta to scan
 * @param uriToLayoutMapping - mapping object which will be changed by this function
 * @param compLayout - connected component layout
 */
export function collectUriToLayoutMapping(
    meta: StylableMeta,
    uriToLayoutMapping: Record<string, ILayoutMapItem[]>,
    compLayout: ILayout
) {
    meta.ast.walkDecls(decl => {
        const regex = new RegExp(/wixMediaUrl\(\s*(\S*)\s*,\s*(\S*)\s*,\s*(\S*)\s*\)/g); // Retrieve formatter args
        let result;
        do {
            result = regex.exec(decl.value);
            if (result) {
                // formatter found
                const [, uri, width, height] = result;

                // Add comp layout to map under matching uri:
                uriToLayoutMapping[uri] = uriToLayoutMapping[uri] || [];
                uriToLayoutMapping[uri].push({
                    layout: compLayout,
                    originalWidth: Number.parseInt(width, 10),
                    originalHeight: Number.parseInt(height, 10)
                });
            }
        } while (result)
    });
}

function getMinimumRequiredDimensionsForUri(uriToLayoutMapElement: ILayoutMapItem[]) {
    let maxWidth = 0;
    let maxHeight = 0;

    // Find max width+height
    uriToLayoutMapElement.forEach(item => {
        const { originalWidth, originalHeight } = item;
        const componentWidth = item.layout ? item.layout.width : 0;
        const componentHeight = item.layout ? item.layout.height : 0;

        const widthRatio = componentWidth / originalWidth;
        const heightRatio = componentHeight / originalHeight;

        // Find target dimensions while keeping aspect ratio:
        let targetWidth = originalWidth;
        let targetHeight = originalHeight;
        if (widthRatio > heightRatio) {
            targetWidth = componentWidth;
            targetHeight = originalHeight * widthRatio;
        } else {
            targetHeight = componentHeight;
            targetWidth = originalWidth * heightRatio;
        }

        // Take greatest dimension pair
        if (targetWidth > maxWidth) {
            maxWidth = targetWidth;
            maxHeight = targetHeight;
        }
    });
    return { maxWidth, maxHeight };
}

class InlineModulesContext {
    public staticMediaUrl = '';
    public uriToLayoutMap: Record<string, ILayout> = {};
    public shouldUseWebp: boolean = false;
    public fontThemes: Record<string, DeclarationMap> = {};
    public onSetFonts: () => void = () => { return; };

    public setStaticMediaUrl = (newStaticMediaUrl: string) => (this.staticMediaUrl = newStaticMediaUrl);

    public setUseWebp = (shouldUseWebpIfPossible: boolean) => (this.shouldUseWebp = shouldUseWebpIfPossible);

    public setUriToLayoutMap(uriToLayoutsMap: Record<string, ILayoutMapItem[]>) {
        this.uriToLayoutMap = {};
        // Calculate and store minimal need dimensions per found uri (consolidating all URI appearances)
        Object.keys(uriToLayoutsMap).forEach(uri => {
            const uriToLayoutMapElement = uriToLayoutsMap[uri];
            const { maxWidth, maxHeight } = getMinimumRequiredDimensionsForUri(uriToLayoutMapElement);

            if (maxWidth !== 0 && maxHeight !== 0) {
                this.uriToLayoutMap[uri] = { width: maxWidth, height: maxHeight };
            }
        });
    }
    
    public setFontThemes = (themeData: ThemeDataProps) => {
        this.fontThemes = getFontThemeClasses(themeData);
        this.onSetFonts();
    }
}

export const inlineModulesContext = new InlineModulesContext();
