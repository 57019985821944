import coreFontUtils, { IParsedFont } from 'santa-core-utils/dist/fonts';

import { DeclarationMap, ThemeDataProps } from '../types';
import { THEME_FONT_KEY, THEME_COLOR_KEY } from '../consts';

const { parseStyleFont } = coreFontUtils;

const PARSED_FONT_KEY_TO_CSS_PROP_MAP: Partial<Record<keyof IParsedFont, string>> = {
    fontWithFallbacks: 'font-family',
    size: 'font-size',
    weight: 'font-weight',
    style: 'font-style'
};

const generatePropertyKey = (typeName: string, index: number) => `${typeName}_${index}`;

const getPropertiesAccordingToType = (typeName: string, values?: string[]) =>
    values ? values.reduce((result, value, index) => {
        result[generatePropertyKey(typeName, index)] = value;
        return result;
    }, {} as Record<string, string>) : {};

export function getParsedFontDeclarations(parsedFont: IParsedFont) {
    const parsedFontKeys = Object.keys(PARSED_FONT_KEY_TO_CSS_PROP_MAP) as (keyof IParsedFont)[];
    return parsedFontKeys.reduce((declarations, parsedFontKey) => {
        const currentCSSProp = PARSED_FONT_KEY_TO_CSS_PROP_MAP[parsedFontKey];
        if (currentCSSProp && parsedFont[parsedFontKey] !== undefined) {
            declarations[currentCSSProp] = parsedFont[parsedFontKey] as string;
        }
        return declarations;
    }, {} as DeclarationMap);
}

export function getFontThemeClassNames(themeData: ThemeDataProps): string[] {
    return Object.keys(
        getPropertiesAccordingToType(THEME_FONT_KEY, themeData[THEME_FONT_KEY])
    ).map(fontStyleName => `.${fontStyleName}`);
}

export function getFontThemeClasses(themeData: ThemeDataProps): Record<string, DeclarationMap> {
    const themeFonts = getPropertiesAccordingToType(THEME_FONT_KEY, themeData[THEME_FONT_KEY]);
    const themeColors = getPropertiesAccordingToType(THEME_COLOR_KEY, themeData[THEME_COLOR_KEY]);

    return Object.keys(themeFonts)
        .reduce((fontThemeClasses, fontStyleName) => {
            fontThemeClasses[fontStyleName] = getParsedFontDeclarations(
                parseStyleFont(fontStyleName, themeFonts, themeColors)
            );
            return fontThemeClasses;
        }, {} as Record<string, DeclarationMap>);
}
