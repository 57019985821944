import {createMinimalFS, process, safeParse, Stylable, createDefaultResolver, StylableMeta} from '@stylable/core';
import postcss from 'postcss';
import { ComponentsMetadata } from '@stylable/webpack-extensions';
import coreFontUtils from 'santa-core-utils/dist/fonts';

import {
    IMetricsReporter,
    IPageJson,
    ISiteAssetsCallParams,
    IStyleReturnData,
    ITopology,
    ThemeDataProps,
    StylableCssSiteData,
    StylableCssParsed,
    IModuleFetcher,
    IStylableCssSiteDataItem,
    IStylableCssParsedSiteDataItem, AstMap, ILogger,
} from './types';
import { generateStylableThemeVars, generateStylableBreakpoints } from './utils/injections';
import { jsModulesImport, fontThemesImport } from './utils/directives';
import sizeof from 'object-sizeof';
import {
    CSS_HEADER_SITE_CSS,
    CSS_HEADER_UPLOADED_FONTS,
    METERING_COLLECT_FONTS,
    METERING_FETCH,
    METERING_HIST_RESPONSE_SIZE,
    METERING_HIST_RESPONSE_SIZE_MASTER_SUFFIX,
    METERING_PRE_PARSE,
    METERING_TOTAL,
    METERING_TRANSFORM,
    THEME_DATA,
} from './consts';
import { inlineModules, wrapRequireWithInlineModules } from './inline-modules/inline-modules';
import { findFontsInAst } from './fonts/fonts-stylable';
import { getFontThemeClassNames } from './fonts/font-utils';
import { inlineModulesContext, ILayoutMapItem, collectUriToLayoutMapping } from './inline-modules/context';
import { extractCssDataFromPage } from './santa-utils';
import { fetchMetadataAndLibCSS, LoadModule } from './dynamic-lib-loader';
import { wrapMetricsReporter } from './wrap-metrics-reporter';
import { map } from './map';
import {createRobustKey} from './utils/internal';

const { getUploadedFontFaceStyles } = coreFontUtils;

export type Pages = Record<string, Promise<any>>;
export interface IOptions {
    pageJson?: IPageJson;
    masterJson?: IPageJson;
    isMasterPage?: boolean;
    isMobileView: boolean;
    metricsReporter: IMetricsReporter;
    logger: ILogger;
    returnCompletePojo?: boolean;
    staticMediaUrl: string;
    mediaAsWebp: boolean;
    libCSS?: string;
    mediaRootUrl: string;
    returnSeparatedCss?: boolean;
    separatedCssAsString?: boolean;
}

const stylableInstances = new Map<ComponentsMetadata, Stylable>();
/* init stylable fs - using metadata fs, and process each file once */
export function getStylableCompilerWithMetadata({ metadata }: { metadata: ComponentsMetadata }) {
    let stylableInstance = stylableInstances.get(metadata);
    if (!stylableInstance) {
        stylableInstance = initStylableCompilerWithMetadata({ metadata });
        stylableInstances.set(metadata, stylableInstance);
    }
    return stylableInstance;
}

function generateResponse(css: string | AstMap, fonts: string[], options: IOptions): IStyleReturnData | string {
    const { isMasterPage, mediaRootUrl, returnCompletePojo, libCSS, returnSeparatedCss, separatedCssAsString } = options;

    // Add lib css if required
    const libCssIfNeeded = isMasterPage ? libCSS : '';
    const uploadedFontFaceStyles = getUploadedFontFaceStyles(fonts, mediaRootUrl);
    const fontsPart = uploadedFontFaceStyles.length > 0 ? CSS_HEADER_UPLOADED_FONTS + uploadedFontFaceStyles : '';

    let outputCss;
    if (returnSeparatedCss && returnCompletePojo){
        if (separatedCssAsString) {
            Object.keys(css).forEach(key => {
                (css as AstMap)[key] = (css as AstMap)[key].toString();
            });
        }
        outputCss = {
            global: libCssIfNeeded + fontsPart,
            styles: css as AstMap
        };
        return {
            css: outputCss,
            fonts
        }
    } else {
        const cssPart = css ? CSS_HEADER_SITE_CSS + css : '';
        outputCss = libCssIfNeeded + cssPart + fontsPart;
    }

    if (returnCompletePojo) {
        return {
            css: outputCss,
            fonts,
        };
    } else {
        return outputCss;
    }
}

function calculateObjectSize(obj: any) {
    return sizeof(obj);
}

function unique(a: any[]) {
    return Array.from(new Set(a));
}

function parseBoolean(value?: string): boolean {
    if (value === 'true') {
        return true;
    }
    return false;
}

function getPlainCss(parsedCompDataArray: StylableCssParsed, options: IOptions, stylable: Stylable) {
    // Process and transform css from page
    const uriToLayoutMapping: Record<string, ILayoutMapItem[]> = {};

    // Process ast and extract uri->layout mapping
    const metaMap = parsedCompDataArray.reduce((accum: Record<string, StylableMeta>, item) => {
        const meta = process(item.ast, undefined, () => item.refArrayId || item.styleId);
        collectUriToLayoutMapping(meta, uriToLayoutMapping, item.compLayout);
        accum[createRobustKey(item.styleId, item.refArrayId)] = meta;
        return accum;
    }, {});

    // Update layout mapping:
    inlineModulesContext.setStaticMediaUrl(options.staticMediaUrl);
    inlineModulesContext.setUseWebp(options.mediaAsWebp);
    inlineModulesContext.setUriToLayoutMap(uriToLayoutMapping);

    // Set font themes:
    inlineModulesContext.setFontThemes(getThemeData(options));

    // Transform:
    const transformedObject: AstMap = {};
    Object.keys(metaMap).forEach(key =>{
        const meta = metaMap[key];
        const stylableResults = stylable.createTransformer().transform(meta);
        const ast = stylableResults.meta.outputAst!;
        transformedObject[key] = ast;
    })
    return transformedObject;
}

function getThemeData(options: IOptions) {
    return options.masterJson!.data.theme_data[THEME_DATA] as ThemeDataProps;
}

function getUsedFonts(parsedCompDataArray: StylableCssParsed, options: IOptions) {
    const fontThemeClassNames = getFontThemeClassNames(getThemeData(options));
    const fontsFilter = (decl: postcss.Declaration) =>
        fontThemeClassNames.indexOf((decl.parent as postcss.Rule).selector) === -1;
    const fonts = parsedCompDataArray.reduce((acc: string[], item) => {
        return acc.concat(findFontsInAst(item.ast, fontsFilter));
    }, []);
    return unique(fonts);
}

function preParse(item: IStylableCssSiteDataItem, injection: string): IStylableCssParsedSiteDataItem {
    const fixedContent = injection + '\n' + item.content;
    const ast = safeParse(fixedContent, { from: item.styleId });
    return {
        ...item,
        ast,
    };
}

function processStylableCss(
    cssData: StylableCssSiteData,
    options: IOptions,
    injection: string,
    stylable: Stylable
): IStyleReturnData | string {
    const { metricsReporter } = options;

    // Generate plain css from each style
    const parsedCompDataArray = metricsReporter.runAndReport(
        () => map(cssData, item => preParse(item, injection)),
        METERING_PRE_PARSE
    );

    const fonts = metricsReporter.runAndReport(
        () => getUsedFonts(parsedCompDataArray, options),
        METERING_COLLECT_FONTS
    );

    let css: string | AstMap = metricsReporter.runAndReport(
        () => getPlainCss(parsedCompDataArray, options, stylable),
        METERING_TRANSFORM
    );

    if (!options.returnSeparatedCss) {
        // Turn map of css to one giant css string:
        css = Object.values(css as AstMap).map(ast => ast.toString()).join('\n');
    }
    const response = generateResponse(css, fonts, options);
    metricsReporter.histogram(
        `${METERING_HIST_RESPONSE_SIZE}${options.isMasterPage ? METERING_HIST_RESPONSE_SIZE_MASTER_SUFFIX : ''}`,
        calculateObjectSize(response)
    );
    return response;
}

function getCSSHeaderInjection({ themeData, isMobileView }: { themeData: ThemeDataProps; isMobileView: boolean }) {
    const variableInjection = generateStylableThemeVars(themeData);
    const mobileVariableInjection = generateStylableBreakpoints(isMobileView);

    return [variableInjection, mobileVariableInjection, jsModulesImport, fontThemesImport].join('\n');
}

function wrapHttpsIfNeeded(url: string, addTrailingSlash = false) {
    let retval = url;
    if (retval === undefined) {
        return '';
    }
    if (addTrailingSlash && !retval.endsWith('/')) {
        retval = `${retval}/`;
    }
    if (url.startsWith('http://')) {
        return url.replace('http://', 'https://');
    }
    if (!url.startsWith('https://')) {
        retval = `https://${retval}`;
    }
    return retval;
}

export function initStylableCompilerWithMetadata({ metadata }: { metadata: ComponentsMetadata }) {
    const { fs, requireModule } = createMinimalFS({
        files: metadata.fs,
    });
    const resolveOptions = { alias: metadata.packages, symlinks: false };

    const defaultResolver = createDefaultResolver(fs, resolveOptions);
    const resolveModule = (directoryPath: string, request: string) => {
        return inlineModules[request] ? request : defaultResolver(directoryPath, request);
    };
    const stylable = Stylable.create({
        fileSystem: fs,
        projectRoot: '/',
        requireModule: wrapRequireWithInlineModules(requireModule, inlineModules),
        mode: 'production',
        resolveOptions,
        resolveModule,
    });
    // Process metadata files
    Object.keys(metadata.fs).forEach(filePath => {
        const meta = stylable.process(filePath);
        meta.namespace = metadata.fs[filePath].metadata.namespace || meta.namespace;
    });
    return stylable;
}

export function generateSantaCSS(options: IOptions, stylable: Stylable): IStyleReturnData | string {
    if (!options.masterJson) {
        throw new Error('no masterJson');
    }
    if (!options.masterJson.data.theme_data[THEME_DATA]) {
        // TODO no need for this shit
        throw new Error('no theme data');
    }
    if (options.returnSeparatedCss && !options.returnCompletePojo) {
        throw new Error('generateSantaCSS: returnSeparatedCss is only supported when returnCompletePojo (getAnnotatedStyle) is enabled');
    }

    // normalize topology
    options.staticMediaUrl = wrapHttpsIfNeeded(options.staticMediaUrl);
    options.mediaRootUrl = wrapHttpsIfNeeded(options.mediaRootUrl, true);

    const stylableCssSiteData: StylableCssSiteData = new Map();

    // Gather stylable st-css data from page
    if (options.pageJson) {
        extractCssDataFromPage(options.logger, options.pageJson, stylableCssSiteData, options.isMobileView, options.masterJson);
    }
    extractCssDataFromPage(options.logger, options.masterJson, stylableCssSiteData, options.isMobileView, undefined);
    return processStylableCss(
        stylableCssSiteData,
        options,
        getCSSHeaderInjection({
            themeData: getThemeData(options),
            isMobileView: !!options.isMobileView,
        }),
        stylable
    );
}

export async function callStylableBuild(
    pages: Pages,
    params: ISiteAssetsCallParams,
    metricsReporter: IMetricsReporter,
    moduleFetcher: IModuleFetcher,
    topology: ITopology,
    logger: ILogger
) {
    // const experimentMap = getExperimentsMap(params.stExp);
    // add relevant experiment data to metrics reporting name
    metricsReporter = wrapMetricsReporter(metricsReporter, name => name + (params.stExp ? '_' + params.stExp : ''));

    return await metricsReporter.runAsyncAndReport(async () => {
        const { pageJson, masterPage, isMasterPage } = await metricsReporter.runAsyncAndReport(async () => {
            // Extract masterPage:
            const mp = await pages.masterPage;
            const isMp = Object.keys(pages).length === 1;

            const { masterPage: mpDummy, ...onlyPage } = pages;
            // Extract page
            const pageId = Object.keys(onlyPage)[0];
            const pj = await pages[pageId];
            return { pageJson: pj, masterPage: mp, isMasterPage: isMp };
        }, METERING_FETCH);

        const { libCSS, stylable } = await initStylable(
            params.libVer,
            (fromUrl: string) => moduleFetcher.fetch({ fromUrl }),
            metricsReporter,
            topology
        );

        return generateSantaCSS(
            {
                masterJson: masterPage,
                pageJson,
                isMasterPage,
                libCSS,
                isMobileView: parseBoolean(params.isMobileView),
                metricsReporter,
                returnCompletePojo: parseBoolean(params.getAnnotatedStyle),
                mediaRootUrl: topology.mediaRootUrl,
                staticMediaUrl: topology.staticMediaUrl,
                mediaAsWebp: parseBoolean(params.mediaAsWebp),
                returnSeparatedCss: parseBoolean(params.returnSeparatedCss),
                separatedCssAsString: true,
                logger
            },
            stylable
        );
    }, METERING_TOTAL);
}

async function initStylable(
    libVer: string | undefined,
    loadModule: LoadModule,
    metricsReporter: IMetricsReporter,
    topology: ITopology
) {
    if (typeof libVer !== 'string') {
        throw new Error('Missing libVer param');
    }
    const { libCSS, metadata } = await metricsReporter.runAsyncAndReport(
        () => fetchMetadataAndLibCSS(libVer, loadModule, topology.moduleRepoUrl),
        'fetch-metadata'
    );

    return {
        libCSS,
        stylable: getStylableCompilerWithMetadata({ metadata }),
    };
}
