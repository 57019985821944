import { DeclarationMap, ThemeDataProps } from '../types';
import {
    THEME_COLOR_KEY,
    mobileMediaQueryVarName,
    mobileMediaQueryOff,
    mobileMediaQueryOn
} from '../consts';
import { getFontThemeClasses } from '../fonts/font-utils';

const generateSiteColorVarKey = (x: number, y: number) => `site_${x}_${y}`;

const varsBlock = (declarations: DeclarationMap) =>
    `:vars {\n${Object.keys(declarations)
        .map(name => `    ${name}: ${declarations[name]};`)
        .join('\n')
    }\n}`;

const classRule = (className: string, declarations: DeclarationMap) =>
    `.${className} { ${Object.keys(declarations)
        .map(name => `${name}: ${declarations[name]};`)
        .join(' ')
    } }`;

const COLOR_OFFSET = 11;
const MATRIX_DIMENSION = 5;

export function generateStylableThemeVars(themeData: ThemeDataProps): string {
    const xFromIndex = (index: number) => Math.floor(index / MATRIX_DIMENSION) + 1;
    const yFromIndex = (index: number) => Math.floor(index % MATRIX_DIMENSION) + 1;

    return varsBlock(
        themeData[THEME_COLOR_KEY] !== undefined ?
            themeData[THEME_COLOR_KEY].slice(COLOR_OFFSET).reduce((siteColorVars, color, index) => {
                  siteColorVars[generateSiteColorVarKey(xFromIndex(index), yFromIndex(index))] = color;
                  return siteColorVars;
              }, {} as DeclarationMap)
            : ({} as DeclarationMap)
    );
}

export function generateStylableTheme(themeData: ThemeDataProps, fontThemes?: Record<string, DeclarationMap>): string {
    const siteColorsVarsString = generateStylableThemeVars(themeData);

    const fontThemeClasses = fontThemes || getFontThemeClasses(themeData);
    const fontThemeClassesString = Object.keys(fontThemeClasses)
        .map(fontStyleName => classRule(fontStyleName, fontThemeClasses[fontStyleName]))
        .join('\n');

    return siteColorsVarsString + '\n' + fontThemeClassesString;
}

export function generateStylableBreakpoints(isMobile: boolean): string {
    return varsBlock({
        [mobileMediaQueryVarName]: isMobile ? mobileMediaQueryOn : mobileMediaQueryOff
    });
}
