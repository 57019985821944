
import { InlineModules, TransformationPlugins, CssModifier } from './types';
import { inlineModules, transformationPlugins } from './inline-modules/inline-modules';
import { removeStylableDirectiveRules } from './utils/directives';

export interface IStylableEditorExtensions {
    inlineModules?: InlineModules;
    transformationPlugins?: TransformationPlugins;
    getMinimalCss?: CssModifier;
}

export const extensions: IStylableEditorExtensions = {
    inlineModules,
    transformationPlugins,
    getMinimalCss: removeStylableDirectiveRules
};
