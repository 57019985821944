import { inlineModulesContext } from './context';
import {wixMediaUrlFormatter, wixMediaUrlFormatterOriginalArgsParamname} from '../consts';

const imageClientLib = require('image-client-api/dist/imageClientApi');

export function wixMediaUrlTransformationPlugin(str: string) {
    return str.replace(/url\("(.*?)"\)/g, (origin, _url) => {
        const urlObj = new URL(_url)

        const formatterValues = urlObj.searchParams.get(wixMediaUrlFormatterOriginalArgsParamname);
        if (formatterValues) {
            return `${wixMediaUrlFormatter}(${formatterValues})`;
        } else {
            return origin;
        }
    });
}

function addOriginalFormatterArgsToUrl(imageUrl: string, args: string[]) {
    const paramsPrefix = (imageUrl.includes('?')) ? '&' : '?';
    return `${imageUrl}${paramsPrefix}${wixMediaUrlFormatterOriginalArgsParamname}=${args.join(',')}`;
}

export function wixMediaUrlFactory() {
    return (uri: string, originalWidth: string, originalHeight: string) => {
        const width = Number.parseInt(originalWidth, 10);
        const height = Number.parseInt(originalHeight, 10);

        // Optimize target size:
        const layout = inlineModulesContext.uriToLayoutMap[uri];
        // Default: target size is same as origin size:
        const targetWidth = (layout) ? layout.width : width;
        const targetHeight = (layout) ? layout.height: height;

        const result = imageClientLib.getData(
            imageClientLib.fittingTypes.SCALE_TO_FILL,
            {
                id: uri,
                width,
                height
            },
            {
                width: targetWidth,
                height: targetHeight,
                htmlTag: imageClientLib.htmlTag.BG,
                alignment: 'center'
            }
        );

        let imageUrl = inlineModulesContext.staticMediaUrl + '/' + result.uri;
        if (inlineModulesContext.shouldUseWebp && result.uri.match(/((.jpg)|(.jpeg)|(.png))$/)) {
            imageUrl = imageUrl.concat('.webp');
        }

        imageUrl = addOriginalFormatterArgsToUrl(imageUrl, [uri, originalWidth, originalHeight]);

        return `url("${imageUrl}")`;
    };
}

export default wixMediaUrlFactory();
