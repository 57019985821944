import { generateStylableBreakpoints } from './injections';
import {
    wixMediaUrlName,
    wixMediaUrlFormatter,
    wixFontThemesName,
    mobileMediaQueryVarName, SITE_ST_CSS_PATH
} from '../consts';

interface IIncludeDirectives {
    breakpoints: boolean;
    siteColors: boolean;
    fontClasses: boolean;
    additionalJsModules: boolean;
}

const defaultIncludeDirectives: IIncludeDirectives = {
    breakpoints: true,
    siteColors: true,
    fontClasses: true,
    additionalJsModules: false
};

const wrapCssDirectives = (css: string) =>
    `/* START STYLABLE DIRECTIVE RULES */\n${css}\n/* END STYLABLE DIRECTIVE RULES */\n`;
const removeCssDirectives = (css: string) => css.replace(/\/\* START STYLABLE DIRECTIVE RULES \*\/\n[\s\S]*\n\/\* END STYLABLE DIRECTIVE RULES \*\/\n/, '');

const siteVars = `:import {-st-from: '${SITE_ST_CSS_PATH}'; -st-named: site_1_1, site_2_1, site_3_1, site_4_1, site_5_1, site_1_2, site_2_2, site_3_2, site_4_2, site_5_2, site_1_3, site_2_3, site_3_3, site_4_3, site_5_3, site_1_4, site_2_4, site_3_4, site_4_4, site_5_4, site_1_5, site_2_5, site_3_5, site_4_5, site_5_5;}\n`;
const fontThemeClasses = `:import {-st-from: '${SITE_ST_CSS_PATH}'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n`;
export const jsModulesImport = `:import {-st-from: '${wixMediaUrlName}';-st-default: ${wixMediaUrlFormatter};}`;
export const fontThemesImport = `:import {-st-from: '${wixFontThemesName}';-st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}`;

export const addStylableDirectiveRules = (stCss: string, isMobile: boolean, include: IIncludeDirectives = defaultIncludeDirectives) =>
    wrapCssDirectives(
        (include.breakpoints ? (generateStylableBreakpoints(isMobile) + '\n') : '') +
        (include.siteColors ? siteVars : '') + (include.additionalJsModules ? jsModulesImport : '') +
        (include.fontClasses ? fontThemeClasses : '')
    ) + stCss;

export const removeStylableDirectiveRules = (stCss: string) =>
    removeCssDirectives(stCss) // TODO: Deprecate the replace logic
        .replace(new RegExp(siteVars, 'g'), '')
        .replace(new RegExp(jsModulesImport, 'g'), '')
        .replace(new RegExp(`:vars {\\s+${mobileMediaQueryVarName}:\\s.*;\\s}\\n`, 'gm'), '');
