import { inlineModulesContext } from './context';
import { StylableJSMixins } from '../types';

const fontThemesMap: StylableJSMixins = {};
inlineModulesContext.onSetFonts = () => {
    Object.keys(fontThemesMap).forEach(fontStyleName => {
        delete fontThemesMap[fontStyleName];
    });

    Object.keys(inlineModulesContext.fontThemes).forEach(fontStyleName => {
        fontThemesMap[fontStyleName] = () => inlineModulesContext.fontThemes[fontStyleName];
    });
};

export default fontThemesMap;
