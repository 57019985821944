import { IMetricsReporter } from './types';
export type GetMetricName = (name: string, method: string) => string;

export function wrapMetricsReporter(
    metricsReporter: IMetricsReporter,
    getMetricName: GetMetricName
): IMetricsReporter & { getMetricName: GetMetricName } {
    return {
        getMetricName,
        histogram(metricName: string, value: number) {
            return metricsReporter.histogram(getMetricName(metricName, 'histogram'), value);
        },
        meter(metricName: string) {
            return metricsReporter.meter(getMetricName(metricName, 'meter'));
        },
        reportEndpoint(endpoint: string, duration: number) {
            return metricsReporter.histogram(getMetricName(endpoint, 'reportEndpoint'), duration);
        },
        reportError(err: Error) {
            return metricsReporter.reportError(err);
        },
        runAndReport<T>(method: () => T, methodName: string): T {
            return metricsReporter.runAndReport(method, getMetricName(methodName, 'runAndReport'));
        },
        runAsyncAndReport<T>(method: () => Promise<T>, methodName: string): Promise<T> {
            return metricsReporter.runAsyncAndReport(method, getMetricName(methodName, 'runAsyncAndReport'));
        },
        reportAsyncWithCustomKey(method, methodName, key) {
            return metricsReporter.reportAsyncWithCustomKey(
                method,
                getMetricName(methodName, 'reportAsyncWithCustomKey'),
                key
            );
        }
    };
}
